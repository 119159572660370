.insights-toggle-button
  max-width: 295px
  width: 100%
  display: flex
  align-items: center
  padding: 5px 15px 0 15px
  &.sticky
    position: fixed
    right: 10px
    top: 0px
  .miko-avatar
    width: 40px
    height: 40px
    img
      width: 100%
  .button-text
    font-family: 'Nexa'
    font-style: normal
    font-weight: 900
    font-size: 16px
    line-height: 19px
    color: #FFFFFF
  .btn-toggle
    width: 24px
    height: 24px
    padding: 0px
    display: flex
    align-items: center
    justify-content: center
    background: transparent
    border: none
    outline: none
    box-shadow: none
    &:hover,
    &:focus,
    &:active
      border: none
      outline: none
      box-shadow: none
    img
      width: 20px