.game-mobile-nav
  margin: -38px -15px 0px -15px
  display: none
  @media ( max-width: 767px )
    display: block
  .nav-banner
    width: 100%
  .dropdown
    position: relative
    .dropdown-toggle
      width: calc( 100% - 30px ) !important
      height: 54px !important
      background: #01132F !important
      border: 1px solid #FFFFFF !important
      border-radius: 4px !important
      transform: translateY(-27px)
      text-align: left
      padding-left: 20px
      outline: none
      box-shadow: none
      &::after
        position: absolute
        right: 20px
        top: 20px
        width: 10px
        height: 10px
        border-bottom: solid 2px #ffffff
        border-left: solid 2px #ffffff
        border-right: none
        border-top: none
        transform: rotate(-45deg)
      &:focus,
      &:hover,
      &:active
        outline: none
        box-shadow: none
    .dropdown-menu
      width: calc( 100% - 30px )
      background: linear-gradient(180deg, #001431 0%, #000D1F 100%)
      border-radius: 4px
      .dropdown-item
        color: #ffffff
        height: 54px !important
        padding: 0 20px
        display: flex
        align-items: center