@import './colors'
body
  background: #000
  color: #FFF
.App
  display: flex
  background-color: $color-dark-blue
  text-align: center
  color: #fff
  .side-nav.expanded + .main
    width: calc( 100% - 275px )
    @media (max-width: 767px)
      width: calc( 100% - 0px )
  .main
    position: relative
    display: block
    width: calc( 100% - 90px )
    margin-left: auto
    min-height: 100%
    @media (max-width: 767px)
      width: calc( 100% - 0px )
      padding-top: 80px
    .dashboard-titlebar
      @media ( max-width: 767px )
        display: none
      .title-bar
        display: flex
        margin-bottom: 16px
        align-items: center
        @media ( max-width: 1199px )
          flex-direction: column
          align-items: flex-start
        .title
          font-family: 'Cubano'
          font-style: normal
          font-weight: 400
          font-size: 40px
          line-height: 48px
          color: #FFFFFF
          padding: 10px
    .dashboard-container
      display: flex
      .dashboard-content
        // width: calc( 100% - 340px )
        width: calc( 100% - 0px )
        // padding: 38px 15px
        .dashboard-navs
          display: flex
          justify-content: left
          margin-bottom: 10px
          .btn-nav
            padding: 20px // 16px
            background: transparent
            border-radius: 4px
            font-family: 'Filson Soft'
            font-style: normal
            font-weight: 400
            font-size: 28px // 24px
            line-height: 28px // 24px
            color: #BFBFBF
            border: none
            outline: none
            @media (max-width: 1199px) and (min-width: 992px)
              padding: 16px
              font-size: 24px
              line-height: 24px
            @media (max-width: 991px) and (min-width: 768px)
              padding: 10px
              font-size: 18px
              line-height: 18px
            &.active
              background: #001431
              color: #FFFFFF
        .dashboard-charts
          .insights-toggle-button,
          .insights-section
            @media ( max-width: 1199px )
              display: none !important
          .section-divider
            background-color: #1E1E1E
            margin-bottom: 0px
            opacity: 1
          .section-title-bar
            padding: 10px
            display: flex
            align-items: center
            .section-title
              font-family: 'Filson Soft'
              font-style: normal
              font-weight: 400
              font-size: 24px
              line-height: 24px
              color: #FFFFFF
              padding: 10px 0
            .currency-switch
              display: flex
              align-items: center
          .charts-section
            @media ( max-width: 1199px )
              width: 100% !important
          .insights-section
            width: 310px
            max-height: 100%
            overflow: auto
          .chart-filter
            background: #001A41
            border: 1px solid #6EA9FF
            border-radius: 4px
            padding: 24px 64px
            .filter-group
              width: 100%
              display: flex
              flex-direction: column
              align-items: flex-start
              label
                font-family: 'Filson Soft'
                font-style: normal
                font-weight: 400
                font-size: 16px
                line-height: 16px
                color: #FFFFFF
                margin-bottom: 10px
              .form-slider
                width: 100%
                padding: 0 10px
                .MuiSlider-root
                  height: 8px
                  background: #A4A4A4
                  padding: 0px
                .MuiSlider-track
                  height: 8px !important
                .MuiSlider-mark
                  top: -4px
                  height: 10px
                  background: #A4A4A4
                .MuiSlider-thumb
                  &::before
                    background: #FFFFFF
                    border: solid 2px #1370F6
                    
            .filter-buttons
              display: flex
              align-items: center
              justify-content: center
              height: 100%
              .btn-apply-filter
                min-width: 165px
                height: 32px
                background: #1370F6
                border: 1px solid #002F75
                border-radius: 20px
                font-family: 'Filson Soft'
                font-style: normal
                font-weight: 400
                font-size: 16px
                line-height: 16px
                color: #FFFFFF
              .btn-more-filters
                background: transparent
                border: none
                outline: none
                font-family: 'Filson Soft'
                font-style: normal
                font-weight: 400
                font-size: 12px
                line-height: 12px
                text-align: right
                color: #1370F6
          .chart-card
            background: linear-gradient(180deg, #001431 0%, #000D1F 100%)
            border: 1px solid #1370F6
            border-radius: 4px
            margin-bottom: 8px
            height: calc( 100% - 8px )
            .card-header
              display: flex
              padding: 20px 15px 0
              .header-info
                text-align: left
                width: auto
                @media ( min-width: 768px )
                  margin-left: 20px
                .title
                  font-family: 'Filson Soft'
                  font-style: normal
                  font-weight: 400
                  font-size: 14px
                  line-height: 14px
                  text-align: center
                  color: #FFFFFF
                  text-transform: capitalize
                  display: flex
                  align-items: center
                  .info-icon
                    width: 16px
                    height: 16px
                .value
                  font-family: 'Filson Soft'
                  font-style: normal
                  font-weight: 400
                  font-size: 28px
                  line-height: 28px
                  color: #FFFFFF
                  display: flex
                  align-items: flex-start
                  word-break: break-all
                  .token-value
                    display: flex
                    align-items: center
                    word-break: normal
                  .token-icon
                    width: 24px
                    height: 24px
              .header-toolbar
                display: flex
                align-items: center
                width: auto
                margin-left: auto
                @media ( max-width: 767px )
                  border-top: solid 1px #002F75
                  border-bottom: solid 1px #002F75
                  padding: 6px 0
                  max-width: 100%
                  width: 100%
                .time-filters
                  display: flex
                  align-items: center
                  justify-content: space-around
                  max-width: 310px
                  width: 100%
                  @media ( max-width: 767px )
                    max-width: 100%
                  .btn-filter
                    height: 34px
                    background: transparent
                    border-radius: 4px
                    outline: none
                    border: none
                    padding: 10px
                    display: flex
                    align-items: center
                    justify-content: center
                    font-family: 'Filson Soft'
                    font-style: normal
                    font-weight: 350
                    font-size: 14px
                    line-height: 14px
                    text-align: center
                    text-transform: capitalize
                    color: #FFFFFF
                    margin-left: 10px
                    margin-right: 10px
                    &.active
                      background: #1370F6
                .chart-icon
                  width: 24px
                  height: 24px
                  opacity: 0.4
                  margin-left: 50px
                  @media ( max-width: 767px )
                    margin-left: 5%
                    margin-right: 6%
                  &:hover
                    cursor: pointer
                  &.active
                    opacity: 1
              .custom-legend
                display: flex
                max-width: 310px
                .legend-item
                  font-family: 'Filson Soft'
                  font-style: normal
                  font-weight: 400
                  font-size: 12px
                  line-height: 12px
                  color: #E1E1E1
                  padding: 5px
                  display: flex
                  align-items: center
                  .dot
                    position: relative
                    display: block
                    width: 10px
                    height: 10px
                    border-radius: 2px
                    margin: 5px
                    background: white
              
            .card-body
              position: relative
              min-height: 320px
              display: flex
              @media (max-width: 991px)
                flex-direction: column
              .chart-wrapper
                width: 100%
                overflow: hidden
                padding-right: 10px
                background-image: url(../images/background/p2e-watermark.png)
                background-repeat: no-repeat
                background-position: center center
                background-size: 220px 220px
            .metrics-wrapper
              height: 100%
              display: flex
              align-items: center
              justify-content: center
            .chart-sales
              text-align: left
              max-width: 90%
              min-width: 250px
              width: auto
              @media ( max-width: 991px )
                max-width: 100%
              .all-time-sales
                background: #001A41
                border: 1px solid #1370F6
                border-radius: 2px
                padding: 10px 20px
                .sales-title
                  font-family: 'Filson Soft'
                  font-style: normal
                  font-weight: 400
                  font-size: 14px
                  line-height: 14px
                  color: #FFFFFF
                  text-transform: capitalize
                  margin-bottom: 5px
                .sales-value
                  font-family: 'Filson Soft'
                  font-style: normal
                  font-weight: 400
                  font-size: 24px
                  line-height: 24px
                  color: #FFFFFF
                  display: flex
                  align-items: center
                  .token-icon
                    width: 14px
                    height: 14px
                    margin-right: 5px
                  span
                    font-family: 'Filson Soft'
                    font-style: normal
                    font-weight: 300
                    font-size: 12px
                    line-height: 12px
                    color: #8A8A8A
                    margin-left: 5px
              .sales-items
                margin: 20px 0
                .sales-item
                  margin-bottom: 4px
                  padding: 5px 20px
                  background: #001A41
                  border-radius: 2px
                  .sales-title
                    font-family: 'Cubano'
                    font-style: normal
                    font-weight: 400
                    font-size: 10px
                    line-height: 12px
                    color: #8A8A8A
                    text-transform: capitalize
                    margin-bottom: 5px
                  .sales-value
                    font-family: 'Filson Soft'
                    font-style: normal
                    font-weight: 400
                    font-size: 20px
                    line-height: 20px
                    color: #FFFFFF
                    .token-icon
                      width: 14px
                      height: 14px
                      margin-right: 5px
                    span
                      font-family: 'Filson Soft'
                      font-style: normal
                      font-weight: 300
                      font-size: 12px
                      line-height: 12px
                      color: #8A8A8A
                      margin-left: 5px
                .token-item
                  margin-bottom: 4px
                  padding: 10px 15px
                  background: #001A41
                  border-radius: 2px
                  display: flex
                  align-items: center
                  .token-icon
                    width: 24px
                    height: 24px
                    margin-right: 8px
                  .token-value
                    font-family: 'Filson Soft'
                    font-style: normal
                    font-weight: 400
                    font-size: 20px
                    line-height: 20px
                    color: #FFFFFF
                    display: flex
                    align-items: center
                    .token-percent
                      font-family: 'Filson Soft'
                      font-style: normal
                      font-weight: 300
                      font-size: 12px
                      line-height: 12px
                      color: #8A8A8A
                      padding-left: 10px
                .purity-item
                  margin-top: 10px
                  padding: 8px 15px
                  border-radius: 2px
                  display: flex
                  flex-direction: column
                  align-items: flex-start
                  &.pure
                    background: #1370F6
                  &.non-pure
                    background: #001A41
                    border: solid 1px #1370F6
                  &.total
                    background: transparent
                    border: solid 1px #242424
                    .purity-label
                      font-family: 'Cubano'
                      font-style: normal
                      font-weight: 400
                      font-size: 8px
                      line-height: 10px
                      color: #8A8A8A
                    .purity-value
                      font-family: 'Filson Soft'
                      font-style: normal
                      font-weight: 400
                      font-size: 20px
                      line-height: 20px
                      color: #FFFFFF
                  .purity-label
                    font-family: 'Filson Soft'
                    font-style: normal
                    font-weight: 400
                    font-size: 14px
                    line-height: 14px
                    color: #FFFFFF
                  .purity-value
                    margin-top: 5px
                    font-family: 'Filson Soft'
                    font-style: normal
                    font-weight: 400
                    font-size: 28px
                    line-height: 28px
                    color: #FFFFFF
        .mobile-charts
          .section-title
            font-family: 'Cubano'
            font-style: normal
            font-weight: 400
            font-size: 18px
            line-height: 22px
            color: #FFFFFF
            text-align: left
            border-top: 1px solid #004EBF
            padding: 10px
          .chart-card
            .card-header
              flex-direction: column
              .title
                font-family: 'Filson Soft'
                font-style: normal
                font-weight: 500
                font-size: 14px
                line-height: 14px
                color: #FFFFFF
                margin: 15px 0 10px
                display: flex
                align-items: center
                width: 100%
                .info-icon
                  width: 14px
                  height: 14px
                  margin-left: 5px
              .sales-value
                font-family: 'Filson Soft'
                font-style: normal
                font-weight: 400
                font-size: 24px
                line-height: 24px
                color: #FFFFFF
                .token-icon
                  widows: 24px
                  height: 24px
                  margin-right: 8px
            .card-toolbar
              border-top: solid 1px #002F75
              border-bottom: solid 1px #002F75
              padding: 4px 24px
            .card-body
              .sales-card
                background: #001A41
                border-radius: 4px
                margin: 8px
                padding: 16px
                .title
                  font-family: 'Filson Soft'
                  font-style: normal
                  font-weight: 400
                  font-size: 12px
                  line-height: 12px
                  color: #E1E1E1
                .sales-items
                  .sales-item
                    border-top: 1px solid #002F75
                    display: flex
                    align-items: center
                    margin-top: 15px
                    padding: 8px 0px
                    .sales-date
                      font-family: 'Filson Soft'
                      font-style: normal
                      font-weight: 350
                      font-size: 12px
                      line-height: 12px
                      color: #5C5C5C
                    .sales-value
                      font-family: 'Filson Soft'
                      font-style: normal
                      font-weight: 400
                      font-size: 24px
                      line-height: 24px
                      color: #FFFFFF
                      .token-icon
                        width: 16px
                        height: 16px
                        margin-left: 8px
                        margin-right: 8px
                  .token-item
                    height: 40px
                    border-top: 1px solid #002F75
                    margin-top: 15px
                    .token-icon
                      width: 18px
                      height: 18px
                    .token-value
                      font-family: 'Filson Soft'
                      font-style: normal
                      font-weight: 400
                      font-size: 24px
                      line-height: 24px
                      color: #FFFFFF
                  .purity-item
                    height: 40px
                    border-top: 1px solid #002F75
                    border-radius: 4px
                    justify-content: flex-start
                    .purity-label,
                    .purity-value
                      font-family: 'Filson Soft'
                      font-style: normal
                      font-weight: 400
                      font-size: 24px
                      line-height: 24px
                      color: #FFFFFF
            .card-footer
              .btn-submit-feedback
                margin: 80px 0 50px
                height: 32px
                background: #1370F6
                border: 1px solid #002F75
                border-radius: 20px
                font-family: 'Filson Soft'
                font-style: normal
                font-weight: 400
                font-size: 16px
                line-height: 16px
                color: #FFFFFF
        .btn-submit-feedback
          margin-left: auto
          max-width: 170px
          padding: 8px 16px
          background: transparent
          border: 1px solid #002F75
          border-radius: 20px
          font-family: 'Filson Soft'
          font-style: normal
          font-weight: 400
          font-size: 16px
          line-height: 16px
          color: #FFFFFF
          text-decoration: none
          @media ( max-width: 767px )
            margin-right: auto
            background: #1370F6
            border: 1px solid #002F75
            border-radius: 20px
      
      .insights-container
        width: 340px