.latest-blog
  background: #050003
  border-radius: 8px
  padding: 24px
  height: 100%
  .blog-link
    text-decoration: none
  .blog-wrapper
    max-height: 318px
    overflow: hidden
    .thumbnail
      width: 100%
      background: #282828
      display: block
      img
        width: 100%
    .blog-title
      font-family: 'Nexa'
      font-style: normal
      font-weight: 900
      font-size: 24px
      line-height: 1.4
      color: #FFFFFF
      text-align: left
      margin-top: 20px
      // height: 78px
      @media (max-width: 767px)
        font-size: 24px
        line-height: 29px
    .blog-content
      font-family: 'Nexa'
      font-style: normal
      font-weight: 400
      font-size: 18px
      line-height: 1.4
      color: #FFFFFFBB
      text-align: left
      -webkit-line-clamp: 4
      -webkit-box-orient: vertical
      @media (max-width: 767px)
        font-size: 16px
        line-height: 19px
      p
        margin: 0px