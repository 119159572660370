.hero-carousel-container
  padding-bottom: 30px
  .hero-carousel
    .carousel-inner
      .carousel-item
        img
          border-radius: 4px
          pointer-events: none
          user-select: none
    .carousel-indicators
      margin-bottom: 0px
      transform: translateY(28px)
      button
        width: 13px !important
        height: 13px !important
        border-radius: 50%
        background-color: #000000
        border: solid 1px #000000
        margin: 0px 7px
        &.active
          border: solid 1px #1888FE
    .carousel-control-prev,
    .carousel-control-next
      width: 80px