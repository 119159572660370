@font-face
  font-family: "Heebo"
  src: local("MyFont"), url(../fonts/Heebo.ttf) format("truetype")
@font-face
  font-family: "Cubano"
  src: local("Cubano"), url(../fonts/Cubano.ttf) format("truetype")
@font-face
  font-family: "Filson Soft"
  src: local("Filson_Soft"), url(../fonts/Filson_Soft.ttf) format("truetype")
@font-face
  font-family: "Nexa"
  src: local("Nexa"), url(../fonts/Nexa.otf) format("opentype")