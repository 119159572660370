@import '../../sass/colors'

.insights-bar
  text-align: left
  max-width: 295px
  width: 100%
  height: calc(100% - 65px)
  overflow: auto
  background-color: $color-dark-blue
  &.sticky
    position: fixed
    right: 0px
    top: 65px
    padding-right: 10px
  
  .title
    font-family: 'Nexa'
    font-style: normal
    font-weight: 900
    font-size: 16px
    line-height: 19px
    color: #FFFFFF
    display: flex
    align-items: center
    padding: 10px 15px
    .eye-icon
      width: 20
      margin-right: 12px

  .tweets
    .tweet
      text-decoration: none
      display: block
      border-top: 1px solid rgba(255, 255, 255, 0.25)
      padding: 15px 10px
      &:hover
        border: 1px solid #FFFFFF
        border-radius: 2px
      &.sponsored
        border: 1px solid #FFFFFF
        border-radius: 2px
        background: #000000
        .tweet-date
          font-family: 'Nexa'
          font-style: normal
          font-weight: 900
          font-size: 18px
          line-height: 22px
          color: #FFFFFF
      .tweet-date
        font-family: 'Nexa'
        font-style: normal
        font-weight: 400
        font-size: 16px
        line-height: 19px
        color: #A4A4A4
        margin-bottom: 24px
      .tweet-content
        max-height: 75px
        overflow: hidden
        text-overflow: ellipsis
        font-family: 'Nexa'
        font-style: normal
        font-weight: 400
        font-size: 16px
        line-height: 19px
        color: #FFFFFF