.alignleft
  display: inline
  float: left
  margin: 0.5em 2em 1.5em 0
.wp-block-table
  margin: 0 0 1em
  overflow-x: auto
  table
    border-collapse: collapse
    width: 100%
    font-size: 18px
    margin-bottom: 5%
    padding: 0
    border-spacing: 0
    tbody, tr, td
      border: 0
      font-family: inherit
      font-size: 100%
      font-style: inherit
      font-weight: inherit
      margin: 0
      outline: 0
      padding: 0
      vertical-align: baseline
    th, td
      padding: 0.5em
      border: 1px solid
      word-break: normal
      font-weight: 300
      text-align: left
    tr:nth-child(even)
      background: #2c3034
img
  position: relative
  &.aligncenter
    left: 50%
    transform: translateX(-50%)
.wp-block-infobox-infobox
  .eb-infobox-wrapper
    padding-right: 30px
    padding-left: 30px
    padding-bottom: 0px
    background-image: linear-gradient(45deg, #7967ff 0% , #c277f2 100%)
    background-color: #7967ff
    transition: background 0.5s, border 0.5s, border-radius 0.5s, box-shadow 0.5s
    .infobox-wrapper-inner
      display: flex
      .contents-wrapper
        text-align: center
        .title
          margin: 0
        .description
          margin: 0