.careers
  min-height: calc(100vh - 152px)
  text-align: left
  font-family: 'Filson Soft', Nexa
  .content-area
    max-width: 830px
    margin-left: auto
    margin-right: auto
    font-size: 18px
    ul 
      padding-left: 1.8em
      list-style: disc
    .entry-title
      font-size: 42px
      font-weight: 600
    h1, 
    h2, 
    h3
      font-weight: 400
    .btn-send-curriculum
      background: linear-gradient(90deg,#006ad9,#004ebf)
      border: none
      border-radius: 20px
      outline: none
      font-family: Cubano
      font-size: 16px
      line-height: 19px
      font-style: normal
      font-weight: 400
      color: #fff
      text-decoration: none
      display: flex
      align-items: center
      justify-content: center
      height: 35px
      width: 270px