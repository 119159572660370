.reading-progress-bar
  position: fixed
  top: 0px
  left: 0px
  width: 100%
  height: 8px
  background: #011431
  z-index: 10000
  .current-progress
    position: relative
    top: 0px
    left: 0px
    height: 100%
    background: linear-gradient(90deg, #AC216F 0%, #1370F6 100%)