.home
  .featured-games
    background: radial-gradient(56.51% 56.51% at 50% 43.49%, rgba(0, 21, 51, 0.2) 0%, rgba(0, 0, 0, 0.2) 55.21%)
    border-radius: 4px
    padding: 24px
    @media (max-width: 767px)
      padding: 0
    .title
      font-family: 'Cubano'
      font-style: normal
      font-weight: 400
      font-size: 18px
      line-height: 22px
      color: #FFFFFF
      text-align: left
      padding: 10px
      border-top: solid 1px #004EBF
  
  .btn-load-more
      background: transparent
      border: 1px solid #002f75
      border-radius: 20px
      color: #fff
      font-family: Filson Soft
      font-size: 16px
      font-style: normal
      font-weight: 400
      line-height: 16px
      margin-left: auto
      max-width: 170px
      padding: 8px 16px
      text-decoration: none

  .latest-blogs
    background: radial-gradient(56.51% 56.51% at 50% 43.49%, rgba(0, 21, 51, 0.2) 0%, rgba(0, 0, 0, 0.2) 55.21%)
    border-radius: 4px
    padding: 24px
    @media (max-width: 767px)
      padding: 0
    .title
      font-family: 'Cubano'
      font-style: normal
      font-weight: 400
      font-size: 18px
      line-height: 22px
      color: #FFFFFF
      text-align: left
      padding: 10px
      border-top: solid 1px #961561
    .button-wrapper
      padding: 0px 80px
      @media ( max-width: 767px )
        padding: 0px
      .btn-launch-news-hub
        max-width: 450px
        width: 100%
        height: 35px
        background: linear-gradient(90deg, #006AD9 0%, #004EBF 100%)
        border-radius: 20px
        display: flex
        align-items: center
        margin-left: auto
        margin-right: auto
        justify-content: center
        font-family: 'Cubano'
        font-style: normal
        font-weight: 400
        font-size: 16px
        line-height: 19px
        text-align: center
        color: #FFFFFF
        text-decoration: none
        @media ( max-width: 1199px )
          max-width: 100%
        @media ( max-width: 767px )
          height: 43px
          max-width: 266px
          border-radius: 8px
          