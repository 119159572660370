.profile
	.profile-section
		width: 100%
		border-top: 1px solid #1E1E1E
		padding: 10px 0px 50px
		.section-header
			padding: 10px
			font-family: 'Filson Soft'
			font-style: normal
			font-weight: 400
			font-size: 24px
			line-height: 24px
			color: #FFFFFF
			text-align: left
			display: flex
			align-items: center
			@media ( max-width: 767px )
				flex-direction: column
			span
				font-family: 'Filson Soft'
				font-style: normal
				font-weight: 400
				font-size: 16px
				line-height: 16px
				color: #FFFFFF
				@media ( max-width: 991px )
					display: none
			.category-tabs
				@media ( max-width: 767px )
					display: grid
					grid-template-columns: auto auto auto
					background: #011431
					border-radius: 2px
					overflow: hidden
					width: 100%
					margin-top: 10px
				.btn-category
					font-family: 'Filson Soft'
					font-style: normal
					font-weight: 300
					font-size: 18px
					line-height: 18px
					color: #FFFFFF
					border-radius: 0px
					border: none
					outline: none
					box-shadow: none
					@media ( max-width: 767px )
						font-family: Cubano
					&:forcus,
					&:active,
					&:visited
						border: none
						outline: none
						box-shadow: none
					&.active
						font-weight: 900
						color: #1888FE
						@media ( max-width: 767px )
							font-family: Cubano
							font-weight: 400
							color: #FFFFFF
							background: #0048b0
		.section-sub-header
			padding: 10px
			font-family: 'Filson Soft'
			font-style: normal
			font-weight: 400
			font-size: 20px
			line-height: 22px
			color: #FFFFFF
			text-align: left
			display: flex
			align-items: center
			@media ( max-width: 767px )
				flex-direction: column
		.section-card
			background: #00050D
			border-radius: 8px
			padding: 35px 50px 35px 70px
			@media ( max-width: 575px )
				padding: 35px 20px
			.responsive-row
				display: flex
				flex-direction: row
				@media ( max-width: 1024px )
					flex-direction: column
			
			.profile-overview
				padding-right: 40px
				text-align: left
				@media ( max-width: 1024px )
					padding-right: 0px
				.profile-main
					display: flex
					@media ( max-width: 575px )
						flex-direction: column
						align-items: center
					.profile-avatar
						position: relative
						width: 130px
						height: 145px
						background-image: url(../../images/background/profile-bg.png)
						background-repeat: no-repeat
						// background-size: 85%
						background-position: center
						clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%)
						img
							height: 100%
							position: absolute
							top: 50%
							left: 50%
							transform: translate(-50%, -50%)
					.profile-info
						padding-left: 40px
						@media ( max-width: 575px )
							padding-left: 0px
						.profile-headline
							font-family: 'Cubano'
							font-style: normal
							font-weight: 400
							font-size: 32px
							line-height: 38px
							color: #FFFFFF
							display: flex
							align-items: center
							.token-icon
								width: 24px
								height: 24px
								margin-left: 8px
								margin-right: 8px
							.game-status
								font-family: 'Filson Soft'
								font-style: normal
								font-weight: 400
								font-size: 16px
								line-height: 16px
								color: #FFFFFF
								display: flex
								align-items: center
								&::before
									content: "",
									width: 12px
									height: 12px
									display: block
									background-color: #5C5C5C
									border-radius: 12px
									margin-left: 8px
									margin-right: 8px
								&.live::before
									background-color: #34A527
								&.beta::before
									background-color: #1370F6
								&.development::before
									background-color: #5C5C5C

						.profile-subline
							font-family: 'Filson Soft'
							font-style: normal
							font-weight: 400
							font-size: 24px
							line-height: 24px
							color: #FFFFFF
							margin-top: 8px
						.profile-network
							display: flex
							align-items: center
							font-family: 'Filson Soft'
							font-style: normal
							font-weight: 400
							font-size: 16px
							line-height: 16px
							color: #FFFFFF
							margin-top: 8px
							img
								width: 24px
								height: 24px
								margin-right: 8px
						
						.social-items
							display: flex
							justify-content: flex-end
							margin-top: 8px
							@media (max-width: 767px)
								justify-content: center
							.social-item
								display: inline-flex
								width: 24px
								height: 24px
								margin-right: 8px !important
								&:last-child
								margin-right: 0px
							.social-link
								font-family: 'Filson Soft'
								font-style: normal
								font-weight: 400
								font-size: 18px
								line-height: 18px
								// text-decoration-line: underline
								color: #1888FE
								margin-left: 24px
								display: flex
								align-items: center
								justify-content: center

								min-width: 24px
								width: 24px
								height: 24px
								border-radius: 50%
								background-color: #1888FE
								display: flex
								align-items: center
								justify-content: center
								@media (max-width: 767px)
								justify-content: center
								margin-left: auto
								.icon-web
									width: 16px
									height: 16px
									filter: brightness(0) invert(0)
								
				.profile-tokens
					padding: 20px
				.profile-description
					font-family: 'Filson Soft'
					font-style: normal
					font-weight: 300
					font-size: 16px
					line-height: 160%
					color: #FFFFFF
					margin-bottom: 20px

			.overview-images
				max-width: 480px
				min-width: 420px
				width: 100%
				display: grid
				grid-template-columns: auto auto
				// justify-content: center
				align-items: center
				align-content: center
				@media ( max-width: 1024px )
					max-width: 100%
					grid-template-columns: auto auto auto auto
				@media ( max-width: 575px )
					max-width: 100%
					min-width: 100%
					grid-template-columns: auto auto
					margin: 0 auto
				.overview-image-item
					position: relative
					max-width: 225px
					border-radius: 8px
					overflow: hidden
					margin: 10px
					&:hover
						opacity: 0.8
					.overview-image-wrapper
						position: relative
						width: 100%
						padding-top: 100%
						cursor: pointer
						.overview-image
							width: auto
							height: 100%
							position: absolute
							top: 50%
							left: 50%
							transform: translate(-50%, -50%)
					.overlay-more
						position: absolute
						width: 100%
						height: 100%
						top: 0px
						left: 0px
						background: #00000088
						display: flex
						align-items: center
						justify-content: center
						font-family: 'Filson Soft'
						font-style: normal
						font-weight: 400
						font-size: 24px
						line-height: 24px
						color: #FFFFFF
						cursor: pointer

		.slick-slider
			.slick-list
				overflow: hidden
				.slick-track
					display: flex
					.slick-slide
						padding: 0 10px
						& > div
							display: flex
							height: 100%
			.slick-arrow
				position: absolute
				top: 50%
				right: -40px
				@media ( max-width: 767px )
					display: none
				&.slick-prev
					transform: translateY(calc(-50% - 24px))
				&.slick-next
					transform: translateY(calc(-50% + 24px))

		.gameplay
			padding: 28px 85px 28px 14px
			display: flex
			@media ( max-width: 767px )
				padding: 28px 14px
			.gameplay-container
				position: relative
				display: flex
				width: 100%
				.img-wrapper
					// margin-right: 20px
					position: relative
					width: 100%
					height: auto
					padding-top: 56.25%
					display: block
					overflow: hidden
					// background: linear-gradient(180deg,#00122d,rgba(36,36,36,0))
					background-color: #001738
					cursor: pointer
					img
						position: absolute
						top: 50%
						left: 50%
						transform: translate(-50%, -50%)
						height: 100%
				.slick-slide
					// width: auto !important
					// & > div
					// 	img
							// height: 100%

		.videos
			padding: 28px 85px 28px 14px
			@media ( max-width: 767px )
				padding: 28px 14px
			.videos-container
				position: relative
				display: flex
				width: 100%
				.video-item
					text-align: left
					border: 1px solid #7B61FF
					border-radius: 8px
					padding: 0px 24px
					display: flex !important
					cursor: pointer
					@media ( max-width: 1024px )
						flex-direction: column
					.video-wrapper
						flex: 2
						user-select: none
						pointer-events: none
						cursor: pointer
						@media ( max-width: 1024px )
							flex: 1
							margin-bottom: 10px
						video
							width: 100%
							background: #282828
							border-radius: 3px
					.video-thumbnail-wrapper
						position: relative
						flex: 2
						cursor: pointer
						max-width: 40%
						opacity: 0.8
						&:hover
							opacity: 1
						@media ( max-width: 1024px )
							max-width: 100%
							flex: 1
						.thumbnail-img
							user-select: none
							pointer-events: none
							width: 100%
							background: #282828
							border-radius: 3px
						.overlay-icon
							position: absolute
							top: 50%
							left: 50%
							transform: translate(-50%, -50%)
							width: 50px
					.video-info
						padding: 28px 0px 28px 20px
						flex: 3
						@media ( max-width: 1024px )
							flex: 1
							padding: 0px 0px 28px 0px
						.video-title
							font-family: 'Filson Soft'
							font-style: normal
							font-weight: 400
							font-size: 24px
							line-height: 24px
							color: #FFFFFF
							cursor: pointer
						.video-date
							font-family: 'Cubano'
							font-style: normal
							font-weight: 400
							font-size: 16px
							line-height: 19px
							color: #FFFFFF
							margin-top: 8px
							cursor: pointer

		.articles
			padding: 28px 85px 28px 14px
			@media ( max-width: 767px )
				padding: 28px 14px
			.articles-container
				position: relative
				display: flex
				width: 100%
				.article-item
					position: relative
					border: 1px solid #AC216F
					border-radius: 8px
					padding: 20px
					text-align: left
					.article-image
						width: 100%
						min-height: 50px
						background: #282828
						.news-hub-banner
							align-items: center
							background: linear-gradient(180deg,#00122d,rgba(36,36,36,0))
							border: 1px solid #1370f6
							border-radius: 4px
							display: flex
							flex-direction: column
							justify-content: center
							width: 100%
							height: auto
							padding-top: 33.3992%
							position: relative
							.text-banner
								width: 100%
								position: absolute
								top: 50%
								left: 50%
								transform: translate(-50%, -50%)
								.headline
									text-align: center
									font-size: 28px
									margin-bottom: 15px
								.subline
									text-align: center
					.article-headline
						font-family: 'Filson Soft'
						font-style: normal
						font-weight: 500
						font-size: 20px
						line-height: 20px
						color: #FFFFFF
						margin-top: 16px
						text-decoration: none
						display: block
					.article-date
						font-family: 'Filson Soft'
						font-style: normal
						font-weight: 500
						font-size: 14px
						line-height: 1.2
						color: #1888FE
						margin-top: 16px
					.article-subline
						font-family: 'Filson Soft'
						font-style: normal
						font-weight: 400
						font-size: 16px
						line-height: 160%
						letter-spacing: -0.02em
						color: #CDCDCD
						margin-top: 8px
						text-decoration: none
						display: block
					.article-button
						width: 100%
						.btn-launch-news-hub
							margin-top: 70px
							align-items: center
							background: linear-gradient(90deg,#006ad9,#004ebf)
							border-radius: 4px
							color: #fff
							display: flex
							font-family: Cubano
							font-size: 16px
							font-style: normal
							font-weight: 400
							height: 35px
							justify-content: center
							line-height: 19px
							margin-left: auto
							margin-right: auto
							text-align: center
							text-decoration: none

		.token-performance
			padding: 35px 14px
			.filter-bar
				display: flex
				align-items: center
				justify-content: center
				@media ( max-width: 991px )
					flex-direction: column
				.filter-buttons
					display: flex
					.btn-filter
						display: flex
						align-items: center
						justify-content: center
						height: 36px
						border-radius: 4px
						margin: 0px 16px
						font-family: 'Filson Soft'
						font-style: normal
						font-weight: 350
						font-size: 16px
						line-height: 16px
						text-align: center
						color: #FFFFFF
						@media ( max-width: 991px )
							margin: 0px 12px
						@media ( max-width: 575px )
							margin: 0px 5px
							font-size: 12px
						&.active
							background: #002F75
				.date-range-picker
					position: relative
					display: flex
					align-items: center
					margin-left: 20px
					margin-right: 20px
					svg
						position: absolute
						width: 18px
						height: 18px
						margin-left: 16px
					.react-datepicker-wrapper
						.react-datepicker__input-container
							input
								height: 36px
								border: 1px solid #002F75
								border-radius: 4px
								background: transparent
								outline: none
								font-family: 'Filson Soft'
								font-style: normal
								font-weight: 300
								font-size: 16px
								line-height: 16px
								color: #FFFFFF
								padding-left: 42px
								padding-right: 16px
								&::placeholder
									font-family: 'Filson Soft'
									font-style: normal
									font-weight: 300
									font-size: 16px
									line-height: 16px
									color: #FFFFFF

			.chart-item
				border: 0.61227px solid #002457
				border-radius: 3px
				margin-top: 24px
				.chart-header
					display: flex
					align-items: center
					font-family: 'Filson Soft'
					font-style: normal
					font-weight: 400
					font-size: 14.9178px
					line-height: 15px
					text-align: center
					color: #FFFFFF
					padding: 15px 40px 0px
					img
						width: 22px
						height: 22px
						margin-right: 8px

.profile-media-popup
	.popup-content
		position: absolute,
		top: 50%,
		left: 50%,
		transform: translate(-50%, -50%),
		width: calc(100% - 0px),
		max-width: 900px,
		max-height: calc(100vh - 250px),
		color: #FFF,
		background: linear-gradient(180deg, #001431 0%, #000D1F 100%),
		border: 2px solid #1370F6,
		border-radius: 2px,
		box-shadow: 24px,
		padding: 0px,
		z-index: 10000,
		overflow: auto
		@media ( max-width: 1024px )
			max-width: calc( 100% - 180px )
		@media ( max-width: 767px )
			max-width: calc( 100% - 20px )
		&:hover
			.popup-arrow
				opacity: 1
				transition: opacity 0.35s
			.mobile-arrows
				// height: 40px
				// transition: height 0.35s
		.popup-arrow
			opacity: 0
			transition: opacity 0.35s
		.mobile-arrows
			position: relative
			display: none
			// height: 0px
			// transition: height 0.35s
			overflow: hidden
			@media ( max-width: 767px )
				display: flex
			.popup-arrow-prev,
			.popup-arrow-next
				position: relative
				height: 40px
				width: 50%
				display: inline-flex
				top: 0px
				transform: translateY(0px)
				svg
					font-size: 36px
		.popup-arrow-prev
			position: absolute
			top: 50%
			left: 0px
			width: 60px
			height: 60px
			transform: translateY(-50%)
			background: #00000044
			z-index: 1
			display: flex
			align-items: center
			justify-content: center
			cursor: pointer
			@media ( max-width: 767px )
				display: none
			&:hover
				background: #00000099
			svg
				font-size: 60px
		.popup-arrow-next
			position: absolute
			top: 50%
			right: 0px
			width: 60px
			height: 60px
			transform: translateY(-50%)
			background: #00000044
			z-index: 1
			display: flex
			align-items: center
			justify-content: center
			cursor: pointer
			@media ( max-width: 767px )
				display: none
			&:hover
				background: #00000099
			svg
				font-size: 60px