.about
  // background: #000000
  padding-bottom: 150px
  @media ( max-width: 767px )
    padding-bottom: 70px
  section
    .title
      font-family: 'Cubano'
      font-style: normal
      font-weight: 400
      font-size: 56px
      line-height: 67px
      color: #FFFFFF
      @media ( max-width: 767px )
        font-size: 36px
        line-height: 42px
    .description
      font-family: 'Filson Soft'
      font-style: normal
      font-weight: 400
      font-size: 24px
      line-height: 180%
      text-align: justify
      letter-spacing: 0.02em
      color: #FFFFFF
      @media ( max-width: 767px )
        font-size: 18px
        line-height: 180%
      .line-2
        margin-top: 40px
        @media ( max-width: 767px )
          margin-top: 20px
  .hello-section
    padding: 0 20px
    .img-p2e
      max-width: 270px
      width: 35%
      margin-top: 40px
    .title
      margin-top: 66px
      margin-bottom: 44px
      @media ( max-width: 767px )
        margin-top: 30px
        margin-bottom: 20px
    .description
      max-width: 803px
  .mission-section
    margin-top: 150px
    position: relative
    display: block
    padding: 200px 100px 250px
    @media ( max-width: 767px )
      padding: 120px 50px 150px
      margin-top: 70px
      margin-top: 70px
      .cloud1
        transform: translate(-80px, -35px)
      .cloud2
        transform: translate(-120px, 15px)
      .cloud3
        transform: translate(100px, 50px)
    .frame-mission
      position: absolute
      top: 0px
      left: 50%
      transform: translateX(-50%)
      max-width: 1000px
      width: 100%
      height: 100%
    .cloud1
      max-width: 100px
      width: 10%
      transform: translate(-80px, -35px)
      @media ( max-width: 767px )
        transform: translate(-60px, -10px)
    .cloud2
      max-width: 225px
      width: 22.5%
      transform: translate(-120px, 15px)
      @media ( max-width: 767px )
        transform: translate(-80px, 5px)
    .cloud3
      max-width: 145px
      width: 14.5%
      transform: translate(100px, 50px)
      @media ( max-width: 767px )
        transform: translate(70px, 25px)
    .description
      max-width: 804px
      margin-top: 80px
      @media ( max-width: 767px )
        margin-top: 50px
  .values-section
    margin-top: 150px
    @media ( max-width: 767px )
      margin-top: 70px
      padding: 0 20px
    .img-thund
      margin-top: 12px
      max-width: 100px
      width: 10%
    .section-content
      display: flex
      justify-content: space-around
      align-items: center
      .left-decoration, 
      .right-decoration
        position: relative
        display: block
        width: 130px
        height: 100%
        min-height: 500px
        @media ( max-width: 1199px )
          width: 80px
        @media ( max-width: 767px )
          display: none
        .decoration-img
          width: 100%
          height: 100%
          position: absolute
          top: 50%
          left: 50%
          transform: translate(-50%, -50%)
      .right-decoration
        .decoration-img
          transform: translate(-50%, -50%) rotate(180deg)
      .sub-title
        margin-top: 100px
        font-family: 'Filson Soft'
        font-style: normal
        font-weight: 700
        font-size: 32px
        line-height: 32px
        color: #FFFFFF
        @media ( max-width: 767px )
          margin-top: 50px
          font-size: 28px
      .description
        margin-top: 56px
        max-width: 456px
        @media ( max-width: 767px )
          margin-top: 35px
  .privacy-section
    margin-top: 150px
    padding: 0 20px
    @media ( max-width: 767px )
      margin-top: 70px
    .img-fight
      margin-top: 12px
      max-width: 100px
      width: 10%
    .description
      margin-top: 80px
      max-width: 998px
      width: 100%
      @media ( max-width: 767px )
        margin-top: 40px

