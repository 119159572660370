.dashboard-tokens
  display: block
  text-align: left
  .dashboard-token
    display: inline-flex
    margin: 5px 32px 5px 0
    font-size: 14px
    &:last-child
      margin-right: 0px
    .token-icon
      width: 24px
      height: 24px
      margin-right: 10px