@import './colors'
@import './common'
@import './csbootstrap'
@import './fonts'
@import './layouts'
@import './mui'
@import './wp'

img
  pointer-events: none
  user-select: none
  &.chart-icon
    pointer-events: auto

.apexcharts-canvas
  touch-action: none

ul
  list-style: none
  padding: 0

.flex-column
  display: flex
  flex-direction: column

.lg-show
  display: none !important
  @media (min-width: 992px)
    display: flex !important
    
.lg-hidden
  display: flex !important
  @media (min-width: 992px)
    display: none !important

.blue-top
  border-top: solid 1px #1370F6

.red-top
  border-top: solid 1px #EC2C9E

.bg-temp-img
  background: #282828

.bg-temp-profile
  background: #C4C4C4

.text-purple
  color: #9146FF !important

.text-red
  color: #EC2C9E !important

.px-md-20
  @media ( min-width: 768px )
    padding-left: 20px
    padding-right: 20px
.px-lg-25
  @media ( min-width: 992px )
    padding-left: 25px
    padding-right: 25px