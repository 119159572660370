.nav-divider
  background-color: #5B5B5B
  margin: 15px 0px

.coming-soon
  font-family: 'Cubano'
  font-style: normal

// Tooltip
.tooltip-p2e
  padding: 5px 10px
  .inline-flex
    display: flex
    justify-content: flex-start
    align-items: center
  .tooltip-dot
    width: 8px
    height: 8px
    border-radius: 50%
    display: inline-flex
    margin-right: 5px
    overflow: hidden
    .tooltip-dot-half
      position: relative
      display: inline-flex
      width: 50%
      height: 100%

// Custom Scroll
/* width */
::-webkit-scrollbar
  width: 4px

/* Track */
::-webkit-scrollbar-track
  background: #000916
  border-radius: 4px
 
/* Handle */
::-webkit-scrollbar-thumb
  background: #001738
  border-radius: 4px

/* Handle on hover */
::-webkit-scrollbar-thumb:hover
  background: #001758