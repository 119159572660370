.game-navbar
  @media ( max-width: 767px )
    display: none
    position: relative
  .navs
    display: flex
    background: #011431
    border-radius: 2px
    overflow: hidden
    .nav-item
      width: 100%
      height: 40px
      cursor: pointer
      &.active
        background: #0048B0
      .nav-link
        font-family: 'Cubano'
        font-style: normal
        font-weight: 400
        font-size: 20px
        line-height: 24px
        color: #FFFFFF
        user-select: none


