.side-nav.expanded + .main
  .featured-game
    .game-title-wrapper
      @media ( max-width: 1520px )
        max-width: 100%
        width: 100% !important
  
.featured-game
  padding-top: 50px
  width: 100%
  .card
    position: relative
    background: #00040A
    border-radius: 8px
    width: 100%
    .card-container
      padding: 0 50px
      @media (max-width: 767px)
        padding: 0 20px
      .card-header
        position: relative
        width: 100%
        padding: 0
        transform: translateY(-50px)
        .link-img
          position: relative
          display: block
          width: 100%
          height: auto
          padding-top: 23.37%
          overflow: hidden
          .thumbnail
            position: absolute
            top: 50%
            left: 50%
            transform: translate(-50%, -50%)
            width: 100%
            pointer-events: none
            user-select: none
      .card-body
        padding: 0
        transform: translateY(-35px)
        .game-title-wrapper
          width: max-content
          @media (max-width: 767px)
            width: 100%
          .game-title
            font-family: 'Cubano'
            font-style: normal
            font-weight: 400
            font-size: 32px
            line-height: 38px
            color: #FFFFFF
            display: flex
            @media (max-width: 767px)
              font-size: 24px
              line-height: 29px
              justify-content: center
        .token-items-wrapper
          min-width: 290px
          width: 100%
          .token-items
            width: 100%
            height: 100%
            display: inline-block
            align-items: center
            overflow: hidden
            min-height: 38px
            text-align: left
            @media (max-width: 767px)
              justify-content: center
            .token-item
              display: inline-flex
              align-items: center
              font-family: 'Nexa'
              font-style: normal
              font-weight: 400
              font-size: 12px
              line-height: 14px
              color: #FFFFFF
              margin-right: 10px
              &:last-child
                margin-right: 0px
              .token-icon
                width: 24px
                height: 24px
                margin-right: 5px
        
        .social-items
          display: flex
          // @media (max-width: 767px)
          //   justify-content: center
          //   transform: translateY(-10px)
          .social-item
            display: inline-flex
            width: 24px
            height: 24px
            margin-right: 8px
            &:last-child
              margin-right: 0px
        .social-link
          font-family: 'Filson Soft'
          font-style: normal
          font-weight: 400
          font-size: 18px
          line-height: 18px
          text-decoration-line: underline
          color: #1888FE
          margin-left: auto
          
          min-width: 24px
          width: 24px
          height: 24px
          border-radius: 50%
          background-color: #1888FE
          display: flex
          align-items: center
          justify-content: center
          // @media (max-width: 767px)
          //   justify-content: center
          //   margin-left: auto
          //   margin-right: auto
          .icon-web
            width: 16px
            height: 16px
            filter: brightness(0) invert(0)

        .button-wrapper
          margin-top: 48px
          @media (max-width: 767px)
            margin-top: 24px
          .btn-launch
            display: flex
            justify-content: center
            align-items: center
            width: calc( 100% - 60px )
            height: 35px
            left: 81px
            top: 270px
            background: linear-gradient(90deg, #006AD9 0%, #004EBF 100%)
            border-radius: 20px
            border: none
            outline: none
            margin-left: auto
            margin-right: auto
            font-family: 'Cubano'
            font-style: normal
            font-weight: 400
            font-size: 16px
            line-height: 19px
            text-align: center
            text-decoration: none
            color: #FFFFFF
            @media (max-width: 767px)
              height: 43px
              max-width: 266px
              border-radius: 8px