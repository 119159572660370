.p2e-component-loader
  position: absolute
  top: 0px
  left: 0px
  width: 100%
  height: 100%
  .overlay
    position: relative
    width: 100%
    height: 100%
    background: linear-gradient(180deg, #001431 0%, #000D1F 100%)
    opacity: 0.3