@import '../../sass/colors'

.side-nav
  position: fixed
  width: 100%
  max-width: 275px
  min-height: 100vh
  height: 100%
  overflow: auto
  background: rgba(0, 0, 0, 0.88)
  backdrop-filter: blur(40px)
  padding: 0px 24px 40px
  z-index: 10000
  @media (max-width: 767px)
    position: fixed
    max-width: 100%
    height: 100%
    min-height: 100px
    top: 0px
    left: 0px
    overflow: auto
  &.collapsed
    width: 90px
    @media (max-width: 767px)
      max-width: 100%
      width: 100%
      height: 100px
      background: rgba(0, 4, 10, 0.95)
      backdrop-filter: blur(20px)
      overflow: hidden
    .logo-container
      .toggle-mob-nav
        .dropdown-icon
          transform: rotate(0deg)
    .nav-items
      &.nav-top
        margin-bottom: 0px
        .nav-item
          background: transparent
          .nav-icon
            margin: 24px auto !important
            width: 24px
            height: 24px
          .nav-text
            display: none
      &.nav-bottom
        .nav-item
          background: transparent
          .nav-icon
            margin: 24px auto !important
            width: 24px
            height: 24px
          .nav-text
            display: none
    .social-list
      .btn-social
        border: none !important
        height: auto
        &.twitter
          filter: brightness(0) saturate(100%) invert(48%) sepia(85%) saturate(607%) hue-rotate(165deg) brightness(96%) contrast(98%)
        &.discord
          filter: brightness(0) saturate(100%) invert(58%) sepia(23%) saturate(1058%) hue-rotate(190deg) brightness(90%) contrast(89%)
        &.twitch
          filter: brightness(0) saturate(100%) invert(44%) sepia(89%) saturate(5750%) hue-rotate(251deg) brightness(101%) contrast(101%)
        &.youtube
          filter: brightness(0) saturate(100%) invert(12%) sepia(96%) saturate(7401%) hue-rotate(3deg) brightness(116%) contrast(113%)
        &.facebook
          filter: brightness(0) saturate(100%) invert(21%) sepia(48%) saturate(4581%) hue-rotate(205deg) brightness(83%) contrast(110%)
        span
          display: none
        .icon-social
          margin: 0px
    .game-list,
    .version-title,
    hr
      display: none
    .nav-expand
      .btn-expand
        .icon-expand
          transform: rotate(180deg)

  .logo-container
    width: 100%
    padding: 15px 0
    .logo-link
      display: block
      @media (max-width: 767px)
        display: none
      .logo
        width: 50px
    .toggle-mob-nav
      background-color: transparent
      outline: none
      border: none
      margin-left: auto
      margin-right: auto
      margin-top: 12px
      display: none
      @media (max-width: 767px)
        display: block
      .logo
        width: 230px
      .dropdown-icon
        width: 14px
        height: 8px
        margin: 5px
        transform: rotate(180deg)

  .nav-items
    list-style: none
    padding: 0
    margin-top: 30px
    &.nav-top
      .nav-item
        background: #001738
        border-radius: 2px
    .nav-item
      min-height: 35px
      display: flex
      align-items: center
      justify-content: center
      margin-bottom: 8px
      .nav-banner
        width: 100%
        margin-bottom: 20px
      .nav-link
        width: 100%
        padding: 0px
        display: flex
        align-items: center
        font-family: 'Cubano'
        font-style: normal
        font-weight: 400
        font-size: 16px
        line-height: 19px
        color: #FFFFFF
        .nav-icon
          width: 18px
          height: 18px
          margin: 0px 24px

  .nav-expand
    height: 44px
    display: flex
    align-items: center
    justify-content: flex-end
    padding: 10px
    @media (max-width: 767px)
      display: none
    .btn-expand
      width: 24px
      height: 24px
      background: transparent
      display: flex
      align-items: center
      justify-content: center
      border: none
      outline: none
      .icon-expand
        width: 14px
        height: 14px

  .game-list
    h6
      font-family: 'Cubano'
      font-style: normal
      font-weight: 400
      font-size: 12px
      line-height: 14px
      color: #A9A9A9
    ul
      .game-item
        position: relative
        width: 100%
        min-height: 50px
        border-radius: 4px
        background: #242424
        border: 1px solid #141414
        border-radius: 4px
        display: flex
        align-items: center
        justify-content: center
        overflow: hidden
        // 
        font-family: 'Cubano'
        font-style: normal
        font-weight: 400
        font-size: 20px
        line-height: 24px
        color: #A9A9A9
        .game-img
          width: 100%
          pointer-events: none
          user-select: none
        .coming-soon
          position: absolute
          top: 50%
          left: 50%
          width: 60%
          transform: translate(-50%, -50%)
  .social-list
    .btn-social
      width: 100%
      height: 40px
      background: transparent
      border-radius: 20px
      display: flex
      align-items: center
      justify-content: center
      outline: none
      // 
      font-family: 'Cubano'
      font-style: normal
      font-weight: 400
      font-size: 14px
      line-height: 17px
      color: #FFFFFF
      text-decoration: none
      &.twitter
        border: 2px solid #1DA1F2
      &.discord
        border: 2px solid #7289DA
      &.twitch
        border: 2px solid #9146FF
      &.youtube
        border: 2px solid #FF0000
      &.facebook
        border: 2px solid #0048B0
      .icon-social
        width: 24px
        height: 24px
        margin-left: 10px
        filter: brightness(0) invert(1)
      
  .version-title
    font-family: 'Nexa'
    font-style: normal
    font-weight: 400
    font-size: 12px
    line-height: 14px
    color: #A9A9A9