.careers-header
  position: relative
  .header-container
    width: 100%
    height: 72px
    background: #0F0F0F
    border-radius: 2px
    display: flex
    align-items: center
    @media ( max-width: 767px )
      display: none
    .header-title
      font-family: 'Cubano'
      font-style: normal
      font-weight: 400
      font-size: 20px
      line-height: 24px
      color: #FFFFFF
      text-transform: uppercase
      margin-left: 64px
    .nav-items
      display: flex
      align-items: center
      margin-bottom: 0px
      margin-left: 20px
      @media ( max-width: 991px )
        display: none
      .nav-item
        margin-left: 10px
        &.active
          .nav-link
            color: #1888FE
        .nav-link
          font-family: 'Nexa'
          font-style: normal
          font-weight: 400
          font-size: 20px
          line-height: 24px
          color: #FFFFFF
    .filter-dropdown
      display: none
      @media ( max-width: 991px )
        display: block
        min-width: 200px
        margin-left: auto
        margin-right: 40px
        .dropdown-menu
          border: none

  .mobile-header
    display: none
    text-align: left
    margin-top: 40px
    @media ( max-width: 767px )
      display: block
    .header-title
      border-top: 1px solid #004ebf
      padding: 10px
      font-family: 'Cubano'
      font-style: normal
      font-weight: 400
      font-size: 18px
      line-height: 22px
      color: #FFFFFF
    .filter-dropdown
      .dropdown-toggle
        transform: translateY(40px)

  .filter-dropdown
    position: relative
    .dropdown-toggle
      width: 100%
      height: 54px
      background: #0F0F0F
      border-radius: 4px
      text-align: left
      border: none
      outline: none
      box-shadow: none
      &:hover,
      &:focus,
      &:active
        border: none
        outline: none
        box-shadow: none
      &::after
        position: absolute
        right: 20px
        top: 20px
        width: 10px
        height: 10px
        border-bottom: solid 2px #ffffff
        border-left: solid 2px #ffffff
        border-right: none
        border-top: none
        transform: rotate(-45deg)
    .dropdown-menu
      width: 100%
      background: #0F0F0F
      border-radius: 4px
      border-top: solid 1px #404040
      .dropdown-item
        height: 46px
        display: flex
        align-items: center
        justify-content: center
        font-family: 'Filson Soft'
        font-style: normal
        font-weight: 400
        font-size: 18px
        line-height: 18px
        color: #FFFFFF