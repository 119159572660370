.crab-prices
  .coming-soon-card
    width: 100%
    max-height: 100vw
    height: 550px
    position: relative
    background: linear-gradient(180deg, #001431 0%, #000D1F 100%)
    border: 1px solid #1370F6
    border-radius: 4px
    .coming-soon-img
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
      max-width: 80%