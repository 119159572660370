.call-out-box
  padding: 0 20px
  .call-out-box-wrapper
    position: relative
    padding: 0px 5px
    background: #EED202
    border-radius: 4px
    font-size: 14px
    color: black
    word-break: break-word
    &::before
      content: ''
      position: absolute
      display: block
      background: #EED202
      top: 10px
      left: -4px
      width: 8px
      height: 8px
      transform: rotate(45deg)