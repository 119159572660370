.row
  &.sm
    margin-left: -4px
    margin-right: -4px
    >[class*="col-"]
      padding-left: 4px
      padding-right: 4px

// Laptop Size
@media ( min-width: 1366px )
  .col-sxl-1
    flex: 0 0 auto
    width: 8.333333%
  .col-sxl-2
    flex: 0 0 auto
    width: 16.666666%
  .col-sxl-3
    flex: 0 0 auto
    width: 24.999999%
  .col-sxl-4
    flex: 0 0 auto
    width: 33.333333%
  .col-sxl-5
    flex: 0 0 auto
    width: 41.666666%
  .col-sxl-6
    flex: 0 0 auto
    width: 49.999999%
  .col-sxl-7
    flex: 0 0 auto
    width: 58.333333%
  .col-sxl-8
    flex: 0 0 auto
    width: 66.666666%
  .col-sxl-9
    flex: 0 0 auto
    width: 74.999999%
  .col-sxl-10
    flex: 0 0 auto
    width: 83.333333%
  .col-sxl-11
    flex: 0 0 auto
    width: 91.666666%
  .col-sxl-12
    flex: 0 0 auto
    width: 100%

@media ( min-width: 1440px )
  .col-mxl-1
    flex: 0 0 auto
    width: 8.333333%
  .col-mxl-2
    flex: 0 0 auto
    width: 16.666666%
  .col-mxl-3
    flex: 0 0 auto
    width: 24.999999%
  .col-mxl-4
    flex: 0 0 auto
    width: 33.333333%
  .col-mxl-5
    flex: 0 0 auto
    width: 41.666666%
  .col-mxl-6
    flex: 0 0 auto
    width: 49.999999%
  .col-mxl-7
    flex: 0 0 auto
    width: 58.333333%
  .col-mxl-8
    flex: 0 0 auto
    width: 66.666666%
  .col-mxl-9
    flex: 0 0 auto
    width: 74.999999%
  .col-mxl-10
    flex: 0 0 auto
    width: 83.333333%
  .col-mxl-11
    flex: 0 0 auto
    width: 91.666666%
  .col-mxl-12
    flex: 0 0 auto
    width: 100%

@media ( min-width: 1660px )
  .col-lxl-1
    flex: 0 0 auto
    width: 8.333333%
  .col-lxl-2
    flex: 0 0 auto
    width: 16.666666%
  .col-lxl-3
    flex: 0 0 auto
    width: 24.999999%
  .col-lxl-4
    flex: 0 0 auto
    width: 33.333333%
  .col-lxl-5
    flex: 0 0 auto
    width: 41.666666%
  .col-lxl-6
    flex: 0 0 auto
    width: 49.999999%
  .col-lxl-7
    flex: 0 0 auto
    width: 58.333333%
  .col-lxl-8
    flex: 0 0 auto
    width: 66.666666%
  .col-lxl-9
    flex: 0 0 auto
    width: 74.999999%
  .col-lxl-10
    flex: 0 0 auto
    width: 83.333333%
  .col-lxl-11
    flex: 0 0 auto
    width: 91.666666%
  .col-lxl-12
    flex: 0 0 auto
    width: 100%