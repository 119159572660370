.population-charts
  .chart-card
    .card-header
      .chart-switch-container
        @media (max-width: 991px) and (min-width: 768px)
          min-width: 0px !important
  .table-card
    @media ( min-width: 576px )
      padding: 10px
    @media ( max-width: 575px )
      padding-bottom: 10px
    .population-table
      width: 100%
      thead
        tr
          th
            padding: 15px 5px
            font-family: 'Filson Soft'
            font-style: normal
            font-weight: 400
            font-size: 14px
            line-height: 14px
            text-align: center
            color: #FFFFFF
            &:first-child
              max-width: 100px
      tbody
        tr
          td
            padding: 4px
            font-family: 'Filson Soft'
            font-style: normal
            font-weight: 400
            font-size: 14px
            line-height: 14px
            text-align: center
            color: #FFFFFF
            @media ( max-width: 575px )
              padding: 2px
            &:first-child
              max-width: 100px
            .classIcon
              width: 24px
            .classValue
              padding: 15px 5px
              border-radius: 2px
              @media ( max-width: 575px )
                padding: 15px 3px