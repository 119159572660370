.career-card
  text-align: left
  padding: 32px
  background: #0F0F0F
  border-radius: 4px
  .career-title
    font-family: 'Nexa'
    font-style: normal
    font-weight: 900
    font-size: 20px
    line-height: 24px
    color: #FFFFFF
  .career-content
    margin-top: 24px
    font-family: 'Nexa'
    font-style: normal
    font-weight: 400
    font-size: 16px
    line-height: 19px
    color: #FFFFFF
    display: -webkit-box
    max-height: 58px
    -webkit-line-clamp: 3
    -webkit-box-orient: vertical
    overflow: hidden
  .btn-apply
    margin-top: 24px
    width: 100%
    height: 32px
    border: 1px solid #002F75
    border-radius: 20px
    background: transparent
    font-family: 'Filson Soft'
    font-style: normal
    font-weight: 400
    font-size: 16px
    line-height: 16px
    text-align: center
    color: #1370F6
    @media ( max-width: 767px )
      background: #1370F6
      border: 1px solid #002F75
      border-radius: 20px
      color: #FFFFFF