.footer
  padding: 24px 0px
  background: #000000
  border-radius: 2px
  .footer-top
    display: flex
    align-items: center
    justify-content: center
    strong
      font-family: 'Cubano'
      font-style: normal
      font-weight: 400
      font-size: 20px
      line-height: 24px
      color: #FFFFFF
    span
      font-family: 'Filson Soft'
      font-style: normal
      font-weight: 400
      font-size: 18px
      line-height: 18px
      display: flex
      align-items: flex-end
      color: #FFFFFF
  .footer-middle
    display: flex
    align-items: center
    justify-content: center
    margin-top: 16px
    .social-link
      margin-left: 16px
      &:first-child
        margin-left: 0px
      .icon-social
        width: 24px
        height: 24px
        filter: brightness(0) invert(1)
  .footer-bottom
    display: flex
    align-items: center
    justify-content: center
    margin-top: 16px
    .link-text
      font-family: 'Nexa'
      font-style: normal
      font-weight: 400
      font-size: 18px
      line-height: 22px
      display: flex
      align-items: flex-end
      text-decoration-line: underline
      color: #1888FE
      cursor: pointer