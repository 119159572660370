.resources
  background: #0f0f0f
  text-align: left
  padding-bottom: 100px
  .resources-banner
    background: linear-gradient(180deg, #00122D 0%, rgba(36, 36, 36, 0) 100%)
    border: 1px solid #1370F6
    border-radius: 4px
    height: 280px
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    @media ( max-width: 767px )
      height: 180px
    .title
      font-family: 'Cubano'
      font-style: normal
      font-weight: 400
      font-size: 56px
      line-height: 67px
      color: #FFFFFF
      @media ( max-width: 767px )
        font-size: 38px
        line-height: 46px
    .description
      font-family: 'Nexa'
      font-style: normal
      font-weight: 400
      font-size: 20px
      line-height: 24px
      color: #FFFFFF
      text-align: center
      padding: 0 20px
      margin: 25px 0 0
      @media ( max-width: 767px )
        font-size: 16px
        line-height: 26px
        margin: 10px 0 0
  .digest-card
    background: #0F0F0F
    padding: 24px    
    .miko-insights
      .title
        padding: 10px 0
        text-align: left
        font-family: 'Nexa'
        font-style: normal
        font-weight: 900
        font-size: 20px
        line-height: 24px
        color: #FFFFFF
        display: flex
        .filter
          margin-left: auto
          display: flex
          align-items: center
          font-size: 18px
          .tweet-date
            width: 90px
            display: flex
            align-items: center
            justify-content: center
          .btn-arrow
            width: 24px
            height: 24px
            display: flex
            align-items: center
            justify-content: center
            background: transparent
            color: #FFFFFF
            border: solid 1px #5B5B5B
            svg
              width: 14px
      .insight-items
        min-height: 350px
        max-height: 670px
        height: 100%
        overflow: auto
        @media ( max-width: 767px )
          max-height: 100% !important
        .insight-item
          border: solid 1px #5B5B5B
          // background: #282828
          border-radius: 12px
          padding: 10px
          margin-bottom: 8px
          display: block
          text-decoration: none
          &:last-child
            margin-bottom: 0px
          &:hover,
          &.sponsored
            border: 1px solid #FFFFFF
          .profile
            display: flex
            align-items: center
            .profile-img-wrapper
              width: 44px
              height: 44px
              border-radius: 50%
              overflow: hidden
              background: #000000
              img
                width: 100%
            .profile-info
              margin-left: 10px 
              .profile-name
                font-family: 'Nexa'
                font-style: normal
                font-weight: 900
                font-size: 16px
                line-height: 1.4
                color: #FFFFFF
              .profile-username
                font-family: 'Nexa'
                font-style: normal
                font-weight: 400
                font-size: 16px
                line-height: 1.4
                color: #A4A4A4
          .insight-content
            margin: 15px 0px 0px 0px
            font-family: 'Nexa'
            font-style: normal
            font-weight: 400
            font-size: 16px
            line-height: 19px
            color: #FFFFFF
            display: -webkit-box
            // -webkit-line-clamp: 4
            // -webkit-box-orient: vertical
            // overflow: hidden
            p
              margin: 0px
          .posted-time
            margin-top: 5px
            color: #A4A4A4
          hr
            margin-top: 10px 0px
            background: #A4A4A4

  .daily-digest,
  .blog-section
    .highlight-section
      background: #0F0F0F
      padding: 20px
      .section-header
        display: flex
        @media ( max-width: 990px )
          display: block
        .search-box
          display: flex
          margin-left: auto
          transition: width 2s
          align-items: center
          @media ( max-width: 767px )
            padding-top: 20px
          &.search
            width: 100%
            transition: width 2s
            .search-input
              margin-top: 0px
          .search-input
            width: 100%
            background: transparent
            color: #FFFFFF
            border: solid 1px #5B5B5B
            border-radius: 4px
            min-height: 36px
            padding: 0 10px
            @media ( max-width: 767px )
              width: 100%
    .category-tabs
      @media ( max-width: 767px )
        display: grid
        grid-template-columns: auto auto auto
        background: #011431
        border-radius: 2px
        overflow: hidden
      &.search
        display: none
      .btn-category
        font-family: 'Nexa'
        font-style: normal
        font-weight: 400
        font-size: 18px
        line-height: 24px
        color: #FFFFFF
        border-radius: 0px
        border: none
        outline: none
        box-shadow: none
        @media ( max-width: 767px )
          font-family: Cubano
        &:forcus,
        &:active,
        &:visited
          border: none
          outline: none
          box-shadow: none
        &.active
          font-weight: 900
          color: #1888FE
          @media ( max-width: 767px )
            font-family: Cubano
            font-weight: 400
            color: #FFFFFF
            background: #0048b0
    .title
      padding: 10px 0
      text-align: left
      font-family: 'Nexa'
      font-style: normal
      font-weight: 900
      font-size: 24px
      line-height: 29px
      color: #1888FE
    .category-title
      margin: 0px
      text-align: left
      font-family: 'Nexa'
      font-style: normal
      font-weight: 900
      font-size: 20px
      line-height: 24px
      border-bottom: solid 1px
      padding: 5px 3px
      width: max-content
    .blog-img-wrapper
      position: relative
      display: flex
      // align-items: center
      justify-content: center
      width: 100%
      height: 100%
      overflow: hidden
      @media ( max-width: 767px )
        max-width: 100%
      img
        width: 100%
    .blog-link
      text-decoration: none
      display: block
      width: 100%
      img
        pointer-events: none
        user-select: none
        &.overlay-icon
          position: absolute
          top: 50%
          left: 50%
          transform: translate(-50%, -50%)
          width: 24px
          height: 24px
      .blog-title
        font-family: 'Filson Soft'
        font-style: normal
        font-weight: 900
        font-size: 32px
        line-height: 1.2
        color: #FFFFFF
        display: -webkit-box
        -webkit-line-clamp: 2
        -webkit-box-orient: vertical
        margin-bottom: 15px
        max-height: 80px
        overflow: hidden
        text-overflow: ellipsis
        &.blog-sm
          font-size: 18px
          line-height: 1.2
          max-height: 90px
          overflow: hidden
          text-overflow: ellipsis
      .blog-date
        font-size: 14px
        color:#1888FE
      .blog-content
        font-family: 'Filson Soft'
        font-style: normal
        font-weight: 400
        font-size: 20px
        line-height: 1.2
        color: #FFFFFF
        // min-height: 150px
        // max-height: 220px
        // display: block
        // -webkit-line-clamp: 6
        // -webkit-box-orient: vertical
        // overflow: hidden
        &.blog-sm
          min-height: 21px
          max-height: 60px
          display: -webkit-box
          -webkit-line-clamp: 3
          -webkit-box-orient: vertical
          overflow: hidden
          font-family: 'Filson Soft'
          font-style: normal
          font-weight: 400
          font-size: 16px
          line-height: 20px
          color: #5B5B5B
          user-select: none
          pointer-events: none
        div,
        figure,
        p,
        img
          height: auto
          max-width: 100%
        h1
          font-size: 26px
        h2
          font-size: 22px
        h3
          font-size: 20px
        h4
          font-size: 18px
        h5
          font-size: 16px
        h6
          font-size: 14px
        p
          font-size: 16px
        ul
          list-style: disc
          padding-left: 2rem
        .alignleft
          display: inline
          float: left
          margin: 0.5em 2em 1.5em 0

      .read-more
        color: #A4A4A4
        text-align: right
        font-size: 14px
    .blog-A
      .blog-title
        // font-size: 32px
    .btn-load-more
      background: transparent
      border: 1px solid #002f75
      border-radius: 20px
      color: #fff
      font-family: Filson Soft
      font-size: 16px
      font-style: normal
      font-weight: 400
      line-height: 16px
      margin-left: auto
      max-width: 170px
      padding: 8px 16px
      text-decoration: none

  &.detail
    min-height: calc(100vh - 200px)
    .blog-img-container
      position: relative
      width: 100%
      // min-height: 285px
      background: #242424
      border: 1px solid #0F0F0F
      border-radius: 4px
      overflow: hidden
      img
        width: 100%
        pointer-events: none
        user-select: none
    .blog-title
      font-family: 'Filson Soft'
      font-style: normal
      // font-weight: 600
      font-size: 42px
      line-height: 48px
      color: #FFFFFF
    .blog-date
      font-family: 'Filson Soft'
      font-style: normal
      font-weight: 400
      font-size: 18px
      line-height: 1.2
      color: #1370F6
    .blog-content
      font-family: 'Filson Soft'
      font-style: normal
      font-weight: 350
      font-size: 18px
      line-height: 160%
      letter-spacing: 0.02em
      color: #FFFFFF
      .twitter-tweet 
        &.twitter-tweet-rendered
          margin-left: auto
          margin-right: auto
      iframe
        display: block
        margin-left: auto
        margin-right: auto
      div,
      figure,
      p,
      img
        height: auto
        max-width: 100%
      .wp-block-image
        margin-bottom: 20px
      p
        font-size: 18px
        line-height: 1.6
        margin-bottom: 20px
        overflow-wrap: break-word
      .alignleft
        display: inline
        float: left
        margin: 0.5em 2em 1.5em 0
      h1
        font-size: 36px
        line-height: 1.2
        margin-bottom: 20px
      h2
        font-size: 28px
        line-height: 1.2
        margin-bottom: 20px
      h3
        font-size: 26px
        line-height: 1.2
        margin-bottom: 20px
      h4
        font-size: 24px
        line-height: 1.2
        margin-bottom: 20px
      h5
        font-size: 22px
        line-height: 1.2
        margin-bottom: 16px
      h6
        font-size: 20px
        line-height: 1.2
        margin-bottom: 16px
      ul
        list-style: disc
        padding-left: 2rem
    
    .latest-articles
      .title
        width: 100%
        font-family: 'Nexa'
        font-style: normal
        font-weight: 900
        font-size: 24px
        line-height: 29px
        color: #1888FE
        margin-top: 10px
      .articles-container
        padding: 0px 12px
        overflow-y: auto
        .article-img-wrapper
          position: relative
          display: flex
          width: 100%
          overflow: hidden
          img
            position: relative
            display: block
            width: 100%
            margin-top: auto
            margin-bottom: auto
            pointer-events: none
            user-select: none
        .article-title
          font-family: 'Filson Soft'
          font-style: normal
          // font-weight: 900
          font-size: 18px
          line-height: 1.2
          color: #FFFFFF
          text-decoration: none
          &:hover
            text-decoration: underline

  .video-item
    a
      text-decoration: none
  .video-link
    width: 100%
    .video-img-wrapper
      position: relative
      width: 100%
    img
      pointer-events: none
      user-select: none
      &.overlay-icon
        position: absolute !important
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        width: 24px !important
        height: 24px

  .video-title
    font-family: 'Filson Soft'
    font-style: normal
    font-weight: 400
    font-size: 16px
    line-height: 1.2
    color: #FFFFFF
    max-height: 70px
    overflow: hidden
    text-overflow: ellipsis
  .video-date
    font-family: 'Filson Soft'
    font-style: normal
    font-weight: 900
    font-size: 20px
    line-height: 1.2
    color: #FFFFFF
    display: block
    margin-bottom: 15px
  .channel-links
    display: flex
    align-items: center
    justify-content: flex-end
    .channel-link
      width: 30px
      height: 30px
      background: #FFFFFF
      border-radius: 50%
      display: flex
      align-items: center
      justify-content: center
      margin: 0px 5px
      .channel-icon
        width: 22px
        height: 22px