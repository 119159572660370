.maintenance-card
  position: relative
  .maintenance-bg
    width: 100%
  .maintenance-content
    width: 100%
    height: 100%
    position: absolute
    top: 0px
    left: 0px
    z-index: 1
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    .p2e-logo
      max-width: 500px
      width: 30%
      @media screen and ( max-width: 767px )
        width: 200px
    .maintenance-message
      max-width: 70%
      margin-top: 50px
      font-family: 'Cubano'
      font-style: normal
      font-weight: 400
      font-size: 50px
      line-height: 60px
      text-align: center
      background: linear-gradient(90deg, #EC2C9E -28.65%, #1370F6 127.5%)
      -webkit-background-clip: text
      -webkit-text-fill-color: transparent
      background-clip: text
      text-fill-color: transparent
      @media screen and ( max-width: 1200px )
        margin-top: 30px
        font-size: 30px
        line-height: 40px
      @media screen and ( max-width: 575px )
        margin-top: 20px
        max-width: 80%
        font-size: 25px
        line-height: 30px
        
    